import React, {useEffect, useState} from 'react';
import anime from 'animejs';
import '../assets/styles/app.css';
import '../assets/styles/components/header.css';

const sections = ['Home', 'Education', 'Skills', 'Experience', 'Contact'];

function Header() {
    const [activeSection, setActiveSection] = useState('Home');
    const [isActive, setIsActive] = useState(false);
    const [showLogo, setShowLogo] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight / 2;

            sections.forEach((section) => {
                const sectionElement = document.getElementById(section.toLowerCase());
                if (sectionElement) {
                    const {offsetTop, offsetHeight} = sectionElement;
                    if (
                        scrollPosition >= offsetTop &&
                        scrollPosition < offsetTop + offsetHeight
                    ) {
                        setActiveSection(section);
                    }
                }
            });

            // const sectionEducation = document.getElementById('education');
            // if (scrollPosition > sectionEducation.offsetTop) {
            //     setShowLogo(true);
            // } else {
            //     setShowLogo(false);
            // }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        anime({
            targets: '.header-item',
            fontWeight: (el: HTMLElement) =>
                el.innerText === activeSection ? 700 : 400,
            easing: 'easeInOutQuad',
            duration: 0,
        });
    }, [activeSection]);

    function scroll(section): void | undefined {
        const element = document.getElementById(section.toLowerCase());
        element?.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'center'
        });
    }

    const toggleNavbar = () => {
        setShowLogo(!showLogo);
        setIsActive(!isActive);
    };

    return (
        <div className="header">
            <div className={`name-logo ${showLogo ? 'visible' : 'not-visible'}`}>
                <div onClick={() => scroll('home')}>Anton K.</div>
            </div>
            {sections.map((section) => (
                <button
                    key={section}
                    onClick={() => scroll(section.toLowerCase())}
                    className={`header-item ${activeSection === section ? 'active' : ''}`}
                >
                    {section}
                </button>
            ))}
            <div className={"navbar-mobile"}>
                <nav className="navbar" role="navigation" aria-label="main navigation">
                    <a
                        role="button"
                        className={`navbar-burger ${isActive ? 'is-active' : ''}`}
                        aria-label="menu"
                        aria-expanded="false"
                        onClick={toggleNavbar}
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                    <div className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
                        <div className="navbar-end">
                            {sections.map((section) => (
                                <button
                                    key={section}
                                    onClick={() => {
                                        toggleNavbar()
                                        scroll(section.toLowerCase())
                                    }}
                                    className={`navbar-item ${activeSection === section ? 'active' : ''}`}
                                >
                                    {section}
                                </button>
                            ))}
                        </div>
                    </div>
                </nav>
            </div>

        </div>
    );
}

export default Header;