import React, {useEffect, useState} from 'react';
import './assets/styles/app.css';
import 'bulma/css/bulma.css'
import Home from "./pages/Home";
import Header from "./components/Header";
import Experience from "./pages/Experience";
import Skills from "./pages/Skills";
import Contact from "./pages/Contact";
import Education from "./pages/Education";
import Loading from "./pages/Loading";
import Footer from "./components/Footer";

function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [threshold, setThreshold] = useState(0.3);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1023) {
                setThreshold(0.10);
            } else {
                setThreshold(0.3);
            }
        };

        // Set threshold on component mount and on resize
        handleResize();
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    window.onunload = function () {
        window.scrollTo(0, 0);
    }

    return (
        <div className="app">
            {isLoading ? <Loading setIsLoading={setIsLoading}/> : null}
            <Header/>
            <Home/>
            <Education threshold={threshold}/>
            <Skills/>
            <Experience threshold={threshold}/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default App;