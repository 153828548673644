import React from "react";
import "../assets/styles/app.css"
import "../assets/styles/components/contact_me.css"


function ContactForm() {
    const [result, setResult] = React.useState("");

    const onSubmit = async (event) => {
        try {
            event.preventDefault();
            setResult("Sending....");
            const formData = new FormData(event.target);
            formData.append("access_key", "ec32886f-4f85-45fc-b5e8-94ac37feffae"); // it is not a secret

            const response = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                body: formData
            });

            const status = await response.status;

            if (status == 200) {
                setResult("Thank you! Your message has been sent successfully.");
                event.target.reset();
            } else {
                console.log("Error", response.json());
                setResult("Something went wrong. Please, contact me using another option.");
            }
        } catch (e) {
            setResult("Something went wrong. Please, contact me using another option.");
        } finally {
            setTimeout(() => {
                setResult("");
            }, 5000);
        }

    };

    return (
        <form className={"form-detail"} onSubmit={onSubmit}>
            <div className="form-heading font-grey">
                <p>Get in touch</p>
            </div>
            <div className="field">
                <label className="label font-grey font-form">Name</label>
                <div className="control">
                    <input required name={"username"} className="input" type="text" placeholder="Your name"/>
                </div>
            </div>

            <div className="field">
                <label className="label font-grey font-form">Email</label>
                <div className="control">
                    <input required name={"email"} className="input" type="email" placeholder="Your email"/>
                </div>
            </div>

            <div className="field">
                <label className="label font-grey font-form">Message</label>
                <div className="control">
                    <textarea required name={"message"} className="textarea" placeholder="Your message"/>
                </div>
            </div>

            <div className="control submit-result">
                <button className="button is-medium button-submit">Submit</button>
                <div className="has-text-black">{result}</div>
            </div>

        </form>
    );
}

export default ContactForm