import React from "react";
import "../assets/styles/app.css"
import "../assets/styles/pages/experience.css"
import logoCZM from "../assets/images/logo_czm.png";
import logoTipsport from "../assets/images/logo_tipsport.svg";
import {useInView} from "react-intersection-observer";


function Experience(props) {
    const threshold = props.threshold;

    const [ref1, inView1] = useInView({
        threshold: threshold,
        triggerOnce: true,
    });

    const [ref2, inView2] = useInView({
        threshold: threshold,
        triggerOnce: true,
    });

    const [ref3, inView3] = useInView({
        threshold: threshold,
        triggerOnce: true,
    });

    return (
        <div id="experience" className={"my-section experience"}>
            <div>
                <p className={"section-heading"}>Experience</p>
            </div>
            <div className={"info-under-heading font-grey-alpha-on-w"}>
                I have gained valuable experience throughout my career as a Software Engineer. Here are some of my
                previous roles.
            </div>
            <div className={`section-card section-card-first ${inView1 ? "card-anim-show" : "card-anim-hide"}`}
                 ref={ref1}>
                <div className={"section-card-header"}>
                    <div className={"section-card-header-left"}>
                        <img src={logoCZM} className={"img-czm-logo"} alt={"Logo CZM"}
                             onClick={() => window.open("https://czm.fel.cvut.cz/")}/>
                        {/*<p>Center for knowledge management</p>*/}
                    </div>
                    <div className={"section-card-header-tag"}>
                        Aug 2021 - Jan 2023
                    </div>
                </div>
                <div className={"section-card-content"}>
                    <p>Worked as a Java Backend Developer at Center for knowledge management, where I developed and
                        maintained microservices for web
                        applications at CTU University. Collaborated with cross-functional teams to deliver high-quality
                        software solutions. I was responsible for leading the development and maintenance of
                        microservices used by teaching staff for the annual self-evaluation process, which involved
                        multiple stages and participant groups, as well as managing a service dedicated to generating
                        PDF documents.
                    </p>
                    <p className={"section-content-text"}>
                        Technologies: Java, Spring Boot, Spring Cloud, Spring Security, Kafka,
                        PostgreSQL, OpenAPI, REST, GraphQL.
                    </p>
                    <p className={"section-content-text"}>
                        Methodologies: Agile development practices, Waterfall strategy.
                    </p>
                    <br></br>
                </div>
            </div>
            <div
                className={`section-card section-card-next ${inView2 ? "card-anim-show" : "card-anim-hide"}`}
                ref={ref2}>
                <div className={"section-card-header"}>
                    <div className={"section-card-header-left"}>
                        <img src={logoTipsport} className={"img-tipsport-logo"} alt={"Logo Tipsport"}
                             onClick={() => window.open("https://www.tipsport.cz/")}/>
                    </div>
                    <div className={"section-card-header-tag"}>
                        Mar 2023 - Present
                    </div>
                </div>
                <div className={"section-card-content"}>
                    <p>I am currently working as a Software Engineer at Tipsport. My primary responsibilities
                        include creating, developing, and maintaining microservices, as well as developing and
                        maintaining the backend of the main enterprise application and associated GUI.
                    </p>
                    <p className={"section-content-text"}>
                        Technologies: Java EE, Spring Boot, Spring Cloud, Kafka, Redis, Oracle, PL/SQL, PostgreSQL,
                        MongoDB, REST, Swing.
                    </p>
                    <p className={"section-content-text"}>
                        Methodologies: Agile development practices.
                    </p>
                    <br></br>
                </div>
            </div>
            <div
                className={`section-card section-card-next section-card-last ${inView3 ? "card-anim-show" : "card-anim-hide"}`}
                ref={ref3}>
                <div className={"section-card-header"}>
                    <div className={"section-card-header-left"}>
                        <p className={"has-text-weight-medium"}>Self employed</p>
                    </div>
                    <div className={"section-card-header-tag"}>
                        Jul 2023 - Present
                    </div>
                </div>
                <div className={"section-card-content"}>
                    <p>
                        As a self-employed Software Engineer, I have successfully delivered a wide range of projects,
                        including the full development lifecycle of web-based applications such as landing pages and
                        SPA applications. My work involves collaborating closely with clients to understand their
                        unique requirements and delivering tailored, high-quality software solutions that meet their
                        business objectives.
                    </p>
                    <p className={"section-content-text"}>
                        Technologies: Java, Spring Boot, Spring Cloud, Kafka, PostgreSQL, MongoDB, REST, JavaScript,
                        React, HTML, CSS, Bootstrap, WordPress, Tilda, Figma.
                    </p>
                    <p className={"section-content-text"}>
                        Methodologies: Agile development practices, Waterfall strategy.
                    </p>
                    <br></br>
                </div>
            </div>
        </div>
    );
}

export default Experience
