import "../assets/styles/app.css"
import "../assets/styles/pages/skills.css"
import logoJava from "../assets/images/skills/java.svg";
import logoSpringBoot from "../assets/images/skills/spring-boot.svg";
import logoSpringSecurity from "../assets/images/skills/spring-security.svg";
import logoSpringCloud from "../assets/images/skills/spring-cloud.svg";
import logoSpringData from "../assets/images/skills/spring-data.svg";
import logoHibernate from "../assets/images/skills/hibernate.svg";
import logoJUnit from "../assets/images/skills/junit.png";
import logoOpenAPI from "../assets/images/skills/openapi.svg";
import logoRestAPI from "../assets/images/skills/rest-api.svg";
import logoGraphQL from "../assets/images/skills/graphql.svg";
import logoOracle from "../assets/images/skills/oracle.png";
import logoMongoDB from "../assets/images/skills/mongodb.svg";
import logoPostgreSQL from "../assets/images/skills/elephant.png";
import logoKafka from "../assets/images/skills/kafka.png";
import logoGit from "../assets/images/skills/git.svg";
import logoHTML from "../assets/images/skills/HTML.svg";
import logoCSS from "../assets/images/skills/CSS.svg";
import logoReact from "../assets/images/skills/react.svg";
import logoJavaScript from "../assets/images/skills/js.svg";
import logoTypeScript from "../assets/images/skills/typescript.svg";
import anime from 'animejs';
import React, {useEffect, useState} from "react";

function Skills() {
    const [isActiveSection, setIsActiveSection] = useState(false);
    const [wasPlayed, setWasPlayed] = useState(false);
    const sectionElement = document.getElementById('skills');

    useEffect(() => {
        if (sectionElement && isActiveSection && !wasPlayed) {
            anime({
                targets: '.staggering-grid-demo .el',
                scale: [
                    {value: 0.9, easing: 'easeInOutSine', duration: 500},
                    {value: 1, easing: 'easeInOutSine', duration: 1200}
                ],
                delay: anime.stagger(200, {grid: [5, 4], from: 'center'})
            });
            setWasPlayed(true);
        }
    }, [isActiveSection]);

    useEffect(() => {
        const handleScrollSkills = () => {
            const scrollPosition = window.scrollY + window.innerHeight / 2;
            const sectionElement = document.getElementById('skills');

            if (sectionElement) {
                const {offsetTop, offsetHeight} = sectionElement;
                if (
                    scrollPosition >= offsetTop &&
                    scrollPosition < offsetTop + offsetHeight
                ) {
                    setIsActiveSection(true);
                } else if (scrollPosition < offsetTop || scrollPosition > offsetTop + offsetHeight) {
                    setIsActiveSection(false)
                }
            }
        }
        window.addEventListener('scroll', handleScrollSkills);
        return () => window.removeEventListener('scroll', handleScrollSkills);
    }, []);


    return (
        <div id="skills" className={"my-section skills"}>
            <div>
                <p className={"section-heading"}>Skills</p>
            </div>
            <div className={"info-under-heading font-grey-alpha-on-b"}>
                As a Software Engineer, I have experience with various technologies and frameworks. Here are
                some of the skills I possess.
            </div>
            <div className={`skills-lines staggering-grid-demo`}>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-l"} src={logoJava} alt={"Logo Java"}></img>
                    <p>Java</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoSpringBoot} alt={"Logo Spring Boot"}></img>
                    <p>Spring Boot</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoSpringSecurity}
                         alt={"Logo Spring Security"}></img>
                    <p>Spring Security</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-s"} src={logoSpringCloud} alt={"Logo Spring Cloud"}></img>
                    <p>Spring Cloud</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoSpringData} alt={"Logo Spring Data"}></img>
                    <p>Spring Data</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoHibernate} alt={"Logo Hibernate"}></img>
                    <p>Hibernate</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoJUnit} alt={"Logo JUnit"}></img>
                    <p>JUnit</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-l"} src={logoOpenAPI} alt={"Logo OpenAPI"}></img>
                    <p>OpenAPI</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoRestAPI} alt={"Logo REST API"}></img>
                    <p>REST</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoGraphQL} alt={"Logo GraphQL"}></img>
                    <p>GraphQL</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-s"} src={logoOracle} alt={"Logo Oracle"}></img>
                    <p>Oracle</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoMongoDB} alt={"Logo MongoDB"}></img>
                    <p>MongoDB</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-s"} src={logoPostgreSQL} alt={"Logo PostgreSQL"}></img>
                    <p>PostgreSQL</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoKafka} alt={"Logo Kafka"}></img>
                    <p>Kafka</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoGit} alt={"Logo Git"}></img>
                    <p>Git</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoHTML} alt={"Logo HTML"}></img>
                    <p>HTML</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoCSS} alt={"Logo CSS"}></img>
                    <p>CSS</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoReact} alt={"Logo React"}></img>
                    <p>React</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoJavaScript} alt={"Logo JavaScript"}></img>
                    <p>JavaScript</p>
                </div>
                <div className={"skill-card el"}>
                    <img className={"img-skills-logo-m"} src={logoTypeScript} alt={"Logo TypeScript"}></img>
                    <p>TypeScript</p>
                </div>
            </div>
        </div>
    );
}

export default Skills
