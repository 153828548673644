import "../assets/styles/app.css"
import "../assets/styles/pages/home.css"
import myImage from '../assets/images/profile_photo.jpg';

function Home() {

    function scroll(section): void | undefined {
        const element = document.getElementById(section.toLowerCase());
        element?.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'center'
        });
    }

    return (
        <div id="home" className={"my-section home"}>
            <div className={"div-text"}>
                <div className={"font-hi"}>
                    Hi, Welcome!
                </div>
                <div className={"font-name"}>
                    <span>I am </span>
                    <span className={"name"}>Anton Korolov</span>
                </div>
                <div className={"font-summary text-mobile-justify"}>
                    Experienced Software Engineer with a Bachelor's degree and {new Date().getFullYear() - 2020} years
                    of engineering experience. Highly skilled in developing and leading web-based applications,
                    troubleshooting, debugging code, and working with databases. Committed to writing efficient, clean,
                    well-structured, and maintainable code, with a strong dedication to continuous learning and
                    professional growth as a Software Engineer.
                </div>
                <div>
                    <button className={"button button-contact"} onClick={() => scroll('contact')}>
                        <div className={"fw-750"}>Contact me</div>
                    </button>
                </div>
            </div>
            <div>
                <img src={myImage} alt="Portfolio image" className="image"/>
            </div>
        </div>
    );
}

export default Home
