import React, {useEffect, useRef} from 'react';
import '../assets/styles/pages/loading.css';
import anime from 'animejs';

function Loading(props) {
    const {setIsLoading} = props;
    const nameRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (nameRef.current && containerRef.current) {
            // Initialize with an empty string so nothing is visible initially
            nameRef.current.innerHTML = "";

            // Dynamically insert each letter wrapped in spans, which will later be animated
            const name = "Anton Korolov";
            name.split("").forEach(char => {
                const span = document.createElement("span");
                span.className = "letter";
                span.textContent = char === " " ? "\u00A0" : char; // Handle space
                nameRef.current?.appendChild(span);
            });
            // Anime.js timeline for letter animation, text fade-out, and container fade-out
            anime.timeline({loop: false})
                .add({
                    targets: '#name .letter',  // Target each letter
                    opacity: [0, 1],           // Fade in effect
                    translateY: [50, 0],       // Bounce effect
                    easing: "easeInOutSine",   // Bouncy easing
                    duration: 800,            // Bounce duration
                    delay: (el, i) => 70 * i, // Sequential letter delay
                })
                .add({
                    targets: '#name',           // Target the text container
                    opacity: [1, 0],            // Fade out text effect
                    easing: 'easeInSine',       // Smooth easing for fade out
                    duration: 700,              // Duration of the text fade-out effect
                })
                .add({
                    targets: containerRef.current, // Target container for fade-out
                    opacity: [1, 0],           // Fade out effect
                    easing: 'easeInSine',      // Smooth easing for fade out
                    duration: 700,             // Duration of the container fade-out effect
                    offset: '-=200',          // Start this animation 1000ms before the text fade-out completes
                    complete: () => setIsLoading(false), // Hide component after fade-out
                });
        }
    }, []);

    return (
        <div ref={containerRef} className="loading-overlay">
            <div className="loading-content">
                <div
                    id="name"
                    ref={nameRef}
                    className="loading-content-name"
                >
                    {/* Name will be inserted here */}
                </div>
            </div>
        </div>
    );
}

export default Loading;
