import "../assets/styles/app.css"
import "../assets/styles/pages/contact.css"
import ContactForm from "../components/ContactForm";

function Contact() {
    return (
        <div id="contact" className={"my-section contact"}>
            <div className={"contact-panel"}>
                <div className={"contact-text"}>
                    <div className={"contact-heading section-heading font-orange"}>
                        <p>Contact me</p>
                    </div>
                    <div className={"contact-info"}>
                        <div className={"contact-info-block"}>
                            <p>Email</p>
                            <a href="mailto:iamkorolov@gmail.com"
                               className={"contact-info-value"}>iamkorolov@gmail.com</a>
                        </div>
                        <div className={"contact-info-block"}>
                            <p>LinkedIn</p>
                            <a href={"https://www.linkedin.com/in/antoosha/"}
                               className={"contact-info-value"}>www.linkedin.com/in/antoosha/</a>
                        </div>
                        <div className={"contact-info-block"}>
                            <p>Address</p>
                            <p className={"contact-info-value"}>Prague, Czech Republic </p>
                        </div>
                    </div>
                </div>
                <div className={"form-contact-me"}>
                    <ContactForm/>
                </div>
            </div>
        </div>
    );
}

export default Contact
