import React from "react";
import "../assets/styles/app.css"
import "../assets/styles/pages/education.css"
import cvutLogo from '../assets/images/logo_cvut.svg';
import {useInView} from "react-intersection-observer";

function Education(props) {
    const threshold = props.threshold;

    const [ref1, inView1] = useInView({
        threshold: threshold,
        triggerOnce: true,
    });

    const [ref2, inView2] = useInView({
        threshold: threshold,
        triggerOnce: true,
    });

    return (
        <div id="education" className={"my-section education"}>
            <div>
                <p className={"section-heading"}>Education</p>
            </div>
            <div className={"info-under-heading font-grey-alpha-on-w"}>
                My academic background in software engineering and managerial informatics has provided me with a robust
                foundation of essential skills and knowledge for a career in technology.
            </div>
            <div
                className={`section-card section-card-first ${inView1 ? "card-anim-show" : "card-anim-hide"}`}
                ref={ref1}>
                <div className={"section-card-header"}>
                    <div className={"section-card-header-left"}>
                        <img src={cvutLogo} className={"img-cvut-logo"} alt={"Logo FIT CVUT"}
                             onClick={() => window.open("https://fit.cvut.cz/")}/>
                        <a target="_blank" href={"https://fit.cvut.cz/"}>Bc. Software Engineering</a>
                    </div>
                </div>
                <div className={"section-card-content"}>
                    <p>During my Bachelor's program at the Czech Technical University in Prague, I focused on Software
                        Engineering. This rigorous curriculum provided me with a comprehensive understanding of software
                        development principles, algorithms, data structures, and system design. I gained hands-on
                        experience in developing web-based applications, debugging complex code, and collaborating with
                        peers on various projects. The knowledge and skills acquired during this period have been
                        fundamental to my growth as a Software Engineer.</p>
                    <p className={"section-content-text"}>Faculty of Information Technology</p>
                    <p className={"section-content-text"}>Sep 2020 - Jun 2023</p>
                </div>
            </div>
            <div
                className={`section-card section-card-next section-card-last ${inView2 ? "card-anim-show" : "card-anim-hide"}`}
                ref={ref2}>
                <div className={"section-card-header"}>
                    <div className={"section-card-header-left"}>
                        <img src={cvutLogo} className={"img-cvut-logo"} alt={"Logo FIT CVUT"}
                             onClick={() => window.open("https://fit.cvut.cz/")}/>
                        <a target="_blank" href={"https://fit.cvut.cz/"}>Ing. Managerial Informatics</a>
                    </div>
                </div>
                <div className={"section-card-content"}>
                    Continuing my educational pursuit, I am currently enrolled in the Master's program in Managerial
                    Informatics at the Czech Technical University in Prague. This advanced program bridges the gap
                    between technology and business, providing me with a deeper understanding of information systems,
                    project management, and data analytics. As I progress through this program, I am further honing my
                    ability to manage complex information systems and lead technology-driven projects. My studies are
                    focused on leveraging technology to solve business challenges, making me adept at both technical and
                    managerial aspects of software development.
                    <p className={"section-content-text"}>Faculty of Information Technology</p>
                    <p className={"section-content-text"}>Sep 2023 - Jun 2025 (Currently in progress)</p>
                </div>
            </div>
        </div>
    );
}

export default Education;
