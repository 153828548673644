import React from 'react';
import "../assets/styles/components/footer.css"

function Footer() {
    return (
        <footer className="footer">
            <p>Copyright &copy; {new Date(Date.now()).getFullYear()} All rights reserved</p>
        </footer>
    );
}

export default Footer;